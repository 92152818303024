<section class="pageNotFound">
    <div class="container">
        <div class="row  vh-100 align-items-center">
            <div class="col-12 col-lg-5">
                <h1 class=" text-primary">We Sincerely apologize.</h1>
                <small class="text-secondary">
                    The page you are looking for is no longer here. maybe it was never here in the first place. In any
                    case, we are sorry you were sent on this would goose change, and have already taken steps to have
                    the person responsible fired.
                </small>
                <div class="py-3">
                    <a class="btn btn-outline-primary btn-sm rounded-pill" routerLink="/">Go Home</a>
                </div>
            </div>
            <div class="col-12 col-lg-7">
                <img src="assets/images/bgs/404.svg" class=" img-fluid">
            </div>
        </div>
    </div>
</section>