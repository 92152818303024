import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ConnectionService } from 'ng-connection-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private connectionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  connection$ = this.connectionSubject.asObservable();
  private sidenav!: MatSidenav;
  constructor(
    private location: Location,
    private connectionService: ConnectionService,
    private ngxService: NgxUiLoaderService
  ) { }

  monitorConnection(): void {
    this.connectionService.monitor()
      .subscribe(
        connection => {
          if (connection) {
            this.connectionSubject.next(true);
          } else {
            this.connectionSubject.next(false);
          }
        }
      );
  }

  setSidenav(sidenav: MatSidenav): void {
    this.sidenav = sidenav;
  }

  openSidenav(): Promise<MatDrawerToggleResult> {
    return this.sidenav.open();
  }

  closeSidenav(): Promise<MatDrawerToggleResult> {
    return this.sidenav.close();
  }

  toggleSidenav(): Promise<MatDrawerToggleResult> {
    return this.sidenav.toggle();
  }


  goBack(): void {
    this.location.back();
  }

  goForward(): void {
    this.location.forward();
  }

  showProgressBar(): void {
    this.ngxService.start();
  }
  hideProgressBar(): void {
    this.ngxService.stop();
  }
}
