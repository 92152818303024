import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from 'src/app/models/user';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  api = environment.api;
  jwtHelper: JwtHelperService = new JwtHelperService();
  private userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  user$ = this.userSubject.asObservable();
  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  /**
   * TODO User Init
   */
  authAccount(): Observable<any> {
    return this.http.get<any>(`${this.api}/auth/account`)
      .pipe(
        map(
          response => {
            this.userSubject.next(response);
            return response;
          }
        )
      );
  }

  // sign-in
  signIn(formData: any): Observable<any> {
    return this.http.post<any>(`${this.api}/auth/sign-in`, formData)
      .pipe(tap(response => {
        if (response.token) {
          this.saveToken('token', response.token);
        }
        if (response.refreshToken) {
          this.saveToken('refreshToken', response.refreshToken);
        }
      }));
  }


  // * refresh token
  getRefreshToken(): Observable<any> {
    return this.http.get<any>(`${this.api}/auth/refresh-token`)
      .pipe(tap(response => {
        console.log('Refresh response ' + response);
        if (response.token) {
          this.saveToken('token', response.token);
        }
        if (response.refreshToken) {
          this.saveToken('refreshToken', response.refreshToken);
        }
      }));
  }

  // * get token payload
  getPayload(): any {
    const token = this.getToken('token');
    if (token) {
      try {
        return this.jwtHelper.decodeToken(token);
      } catch (error) {
        return false;
      }
    }
    return false;
  }

  hasRole(roles: string[]): boolean {
    const payload = this.getPayload();
    if (payload && roles.includes(payload.role)) {
      return true;
    } else {
      return false;
    }
  }

  // * is token is valid
  isTokenExpired(title: string): boolean {
    const token = localStorage.getItem(title);
    if (token) {
      return this.jwtHelper.isTokenExpired(token);
    } else {
      return true;
    }

  }

  // * save tokens in local storage
  saveToken(title: string, token: string): void {
    localStorage.setItem(title, token);
  }

  // * get token from local storage
  getToken(title: string): any {
    return localStorage.getItem(title);
  }

  // * sign Out
  signOut(): void {
    localStorage.clear();
    this.userSubject.next(null);
    this.router.navigate(['/sign-in']);
  }
}
