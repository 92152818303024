import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from './services/util.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService, public utilService: UtilService) { this.utilService.monitorConnection(); }

  ngOnInit(): void {
    if (!this.authService.isTokenExpired('token') || !this.authService.isTokenExpired('refreshToken')) {
      this.authService.authAccount().subscribe();
    }
  }
}
