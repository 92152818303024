import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  token = '';
  private refreshing = false;
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.setToken(httpRequest))
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                if (this.authService.isTokenExpired('refreshToken')) {
                  this.authService.signOut();
                }
                console.log(this.authService.isTokenExpired('refreshToken'));
                return this.handel401Error(httpRequest, next);
                break;
              case 400:
                console.log(error);
                break;
              case 404:
                console.log(error);
                break;
              default:
                this.errorHandler(error);
                break;
            }
          } else {
            return throwError(error);
          }
        })
      );
  }

  // * set token
  private setToken(httpRequest: HttpRequest<any>): HttpRequest<any> {
    this.token = this.refreshing ? this.authService.getToken('refreshToken') : this.authService.getToken('token');
    return httpRequest.clone({
      setHeaders: { Authorization: `Bearer ${this.token}` }
    });
  }

  // * handle 401 Error
  private handel401Error(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.refreshing) {
      return this.refreshTokenSubject
        .pipe(
          filter((token: string) => token != null),
          take(1),
          switchMap((token: string) => {
            return next.handle(httpRequest);
          })
        );
    } else {
      this.refreshing = true;
      return this.authService.getRefreshToken()
        .pipe(
          switchMap((response: any) => {
            this.refreshing = false;
            this.refreshTokenSubject.next(response.token);
            return next.handle(this.setToken(httpRequest));
          })
        );
    }
  }

  // ! Handel default Server errors
  errorHandler(error: any): any {
    console.log(error);
  }
}
